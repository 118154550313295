import './Main.css'

const $ = s => document.querySelector(s)
const $$ = s => Array.prototype.slice.apply(document.querySelectorAll(s))

function mainAnimation() {
  // 首屏动画
  $('#screenshot-left').classList.add('_show')
  $('#screenshot-iphone').classList.add('_show')
  $('#screenshot-right').classList.add('_show')
}

function prepareTransition(node) {
  // 首屏二维码动画使用了 transform 定位
  // 为了避免加载时消失动画 在ready时增加transition
  node.style =
    'transition: opacity .37s, -webkit-transform .37s;transition: transform .37s, opacity .37s;transition: transform .37s, opacity .37s, -webkit-transform .37s;'
}

function showVideo() {
  // video 没有 poster 会突变
  // 为避免突变 在准备好了之后再渐现
  $$('.video-ctnr').forEach(node => {
    node.classList.add('_show')
    node.play && node.play()
  })
}

function clickShow(clickNodes, showNodes) {
  document.body.addEventListener('click', () => {
    showNodes.forEach(node => {
      node.classList.remove('_show')
    })
  })
  clickNodes.forEach((clickNode, clickIndex) => {
    clickNode.addEventListener('click', e => {
      showNodes.forEach((showNode, showIndex) => {
        if (showIndex === clickIndex) showNode.classList.add('_show')
        else showNode.classList.remove('_show')
      })
      e.stopPropagation()
    })
  })
}

window.addEventListener('DOMContentLoaded', () => {
  const qrcodeApple = $('.qr-ctnr_apple')
  const qrcodeAndroid = $('.qr-ctnr_android')
  const qrcodeBCZ = $('#gh-bcz')
  const qrcodeBCZZP = $('#gh-bczzp')

  mainAnimation()
  prepareTransition(qrcodeAndroid)
  prepareTransition(qrcodeApple)
  prepareTransition(qrcodeBCZ)
  prepareTransition(qrcodeBCZZP)
  clickShow(
    [$('#btn-apple'), $('#btn-android'), $('#btn-bcz'), $('#btn-bczzp')],
    [qrcodeApple, qrcodeAndroid, qrcodeBCZ, qrcodeBCZZP]
  )
})

window.addEventListener('load', () => {
  showVideo()
})
